<template>
  <div
    v-if="isBannerVisible"
    class="bg-neutral-dark-2 flex w-full justify-center rounded-md border px-8 py-4 shadow-lg"
  >
    <div
      class="max-w-prose whitespace-pre-line text-center text-sm leading-normal"
      v-html="banner.sanitizedHtml"
    />
  </div>
</template>

<script setup lang="ts">
import type { BannerPage, Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
  page: string
}

const props = defineProps<Props>()

const appConfig = useAppConfig()
const { $isWidgetMode } = useNuxtApp()
const { data, status } = await useAsyncGql('FetchBanner', {
  cinemaId: props.cinema.id,
  page: props.page as BannerPage,
})

const pending = computed(() => status.value === 'pending')
const banner = computed(() => data.value?.banner ?? null)

const isBannerVisible = computed(() => {
  // First check widget mode - return false immediately if in widget mode and banners disabled
  if ($isWidgetMode.value && !appConfig.enableCinemaBannersInWidgetMode) {
    return false
  }

  // Only show if we have banner content and not pending
  return !pending.value && !!banner.value?.sanitizedHtml
})

defineOptions({
  name: 'CinemaBanner',
})
</script>
