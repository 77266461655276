<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const { t } = useI18n()

usePageMetas({
  title: `${cinema.name} | ${t('subscription')}`,
  ...cinema.meta,
  robots: {
    noindex: true,
    index: false,
    follow: false,
  },
})

defineI18nRoute({
  paths: {
    de: '/kino/[citySlug]/[cinemaSlug]/abo',
    en: '/cinema/[citySlug]/[cinemaSlug]/subscription',
    es: '/cine/[citySlug]/[cinemaSlug]/suscripcion',
  },
})

defineOptions({
  name: 'CinemaSubscriptionPage',
})
</script>

<template>
  <div class="contents">
    <SubscriptionsCheckVisits />
  </div>
</template>

<i18n>
de:
  subscription: "Abo"
en:
  subscription: "Subscription"
es:
  subscription: "Suscripción"
</i18n>
