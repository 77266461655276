<template>
  <div>
    <p>
      <template v-if="city && hasFiltersApplied">
        {{
          t('errors.noResultsInCityWithFilters', { city: city?.name, items })
        }}
      </template>
      <template v-else-if="!city && hasFiltersApplied">
        {{ t('errors.noResultsWithFilters', { items }) }}
      </template>
      <template v-else-if="city">
        {{ t('errors.noResultsInCity', { city: city?.name, items }) }}
      </template>
      <template v-else>
        {{ t('errors.noResults', { items }) }}
      </template>
    </p>

    <template v-if="hasFallbackResults">
      <Button
        v-if="hasFiltersApplied"
        :theme="BUTTON_THEME.TERTIARY"
        :size="BUTTON_SIZE.SM"
        icon="kh:trash-can-outline"
        :text="t('btn.deleteAllFilters')"
        class="mt-4 [&>span]:underline"
        @click="resetAllFilters"
      />

      <PageSectionHeading
        class="mt-14"
        :tag="PAGE_HEADING_TAG.H3"
        :title="t('allItems', { items })"
      />
      <small
        v-if="!!city"
        class="text-base"
        v-text="t('inCity', { city: city.name })"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useEventBus } from '@vueuse/core'

interface Props {
  type: EmptyResultType
  hasFiltersApplied?: boolean
  hasFallbackResults?: boolean
  urlPrefix?: string
}

defineOptions({
  name: 'EmptyResultHandler',
})

const props = defineProps<Props>()
const { t } = useI18n()
const items = t(`type.${props.type}`)
const router = useRouter()
const route = useRoute()
const { city } = await useCity({
  urlPrefix: props.urlPrefix,
})
const bus = useEventBus(
  [['filters', props.urlPrefix]].filter(Boolean).join('_')
)

function resetAllFilters() {
  const filters: UrlFilter[] = Object.values(URL_FILTER)

  bus.emit({
    ...filters.reduce((acc, key) => {
      acc[key] = undefined
      return acc
    }, {}),
  })

  router.replace({
    query: {
      ...route.query,
      ...filters?.reduce((acc, key) => {
        acc[getFilterUrlKey(key, props.urlPrefix)] = undefined
        return acc
      }, {}),
    },
  })
}
</script>

<i18n>
de:
  errors:
    noResultsInCityWithFilters: "Leider gibt es in {city} keine {items} mit den aktuellen Filtereinstellungen."
    noResultsInCity: "Leider gibt es in {city} keine {items}."
    noResults: "Leider gibt es keine {items}."
    noResultsWithFilters: "Leider gibt es keine {items} mit den aktuellen Filtereinstellungen."
  type:
    SHOWS: "Vorstellungen"
    CINEMAS: "Kinos"
    MOVIES: "Filme"
  allItems: "Alle {items}"
  inCity: "in {city}"
  btn:
    deleteAllFilters: "Alle Filter löschen"
es:
  errors:
    noResultsInCityWithFilters: "Desafortunadamente, no hay {items} en {city} con los filtros actuales."
    noResultsInCity: "Desafortunadamente, no hay {items} en {city}."
    noResults: "Desafortunadamente, no hay {items}."
    noResultsWithFilters: "Desafortunadamente, no hay {items} con los filtros actuales."
  type:
    SHOWS: "Funciones"
    CINEMAS: "Cines"
    MOVIES: "Películas"
  allItems: "Todos los {items}"
  inCity: "en {city}"
  btn:
    deleteAllFilters: "Borrar todos los filtros"
</i18n>
