<script setup lang="ts">
const { subscriptionInfos, clearSubscriptions } = await useSubscriptionInfo()

onBeforeUnmount(() => {
  clearSubscriptions()
})

defineOptions({
  name: 'SubscriptionsCheckVisits',
})
</script>

<template>
  <div class="subscription-check">
    <SubscriptionsCheckVisitsForm />

    <div class="mt-8 grid grid-cols-1 gap-4 md:grid-cols-2">
      <SubscriptionsCheckVisitsInfo
        v-for="(subscriptionInfo, index) in subscriptionInfos"
        :key="index"
        :subscription-info="subscriptionInfo"
      />
    </div>
  </div>
</template>
