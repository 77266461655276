<template>
  <div
    class="border-neutral-dark-1 dark:bg-dark bg-light relative rounded-lg border p-6"
  >
    <div class="flex items-center justify-between">
      <h3 class="text-lg font-semibold">
        {{ subscriptionInfo?.product?.displayName }}
        <small
          class="text-neutral-dark block text-xs"
          v-text="`(${subscriptionInfo?.product?.servicePlanLabel})`"
        />
      </h3>
      <Badge :theme="badgeTheme" class="text-sm">
        {{ t(`status.${subscriptionInfo?.status}`) }}
      </Badge>
    </div>

    <Button
      :theme="BUTTON_THEME.TERTIARY"
      :size="BUTTON_SIZE.SM"
      :title="t('btn.closeThisWindow')"
      icon="kh:close"
      class="text-dark bg-light dark:text-light dark:bg-neutral-dark-2 hover:bg-neutral-dark-1 absolute right-1 top-1 z-20 rounded-full transition-colors duration-200"
      @click="removeSubscription(subscriptionInfo.vouchercode)"
    />

    <div class="mt-6">
      <div>
        <span
          class="pr-1 text-2xl font-bold"
          v-text="subscriptionInfo.remainingVisitsCurrentPeriod"
        />
        <span
          class="text-neutral-dark"
          v-text="
            t('visitsRemaining', {
              total: subscriptionInfo.maxVisitsPerPeriod,
            })
          "
        />
      </div>
      <div
        class="dark:bg-neutral-dark-1 bg-neutral-light-1 mt-2 h-2 overflow-hidden rounded-full"
      >
        <div
          class="bg-primary h-full rounded-full"
          :style="`width: ${(subscriptionInfo.remainingVisitsCurrentPeriod / subscriptionInfo.maxVisitsPerPeriod) * 100}%`"
        />
      </div>
      <p class="text-neutral-dark mt-1 text-sm">
        {{ t('validDays', { days: remainingDays }) }}
      </p>
    </div>

    <div class="mt-6">
      <dt class="text-neutral-dark mb-2 font-bold">{{ t('validCinemas') }}:</dt>
      <dd>
        <ul class="space-y-1 text-sm leading-tight">
          <li v-for="cinema in subscriptionInfo.validCinemas" :key="cinema.id">
            {{ cinema.name }}
          </li>
        </ul>
      </dd>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SubscriptionInfo } from '#gql/default'
import { BADGE_THEME } from '@base/constants/badge'
import { BUTTON_SIZE, BUTTON_THEME } from '@base/constants/button'

interface Props {
  subscriptionInfo: SubscriptionInfo
}

const { subscriptionInfo } = defineProps<Props>()

const { t } = useI18n()
const { removeSubscription } = await useSubscriptionInfo()

const remainingDays = computed(() => {
  if (!subscriptionInfo?.periodEndDate) {
    return 0
  }

  const endDate = new Date(subscriptionInfo.periodEndDate)
  const diff = endDate.getTime() - Date.now()
  return Math.max(Math.ceil(diff / (1000 * 3600 * 24)), 0)
})

const badgeTheme = computed(() => {
  switch (subscriptionInfo?.status) {
    case 'ACTIVE':
      return BADGE_THEME.SUCCESS
    case 'EXPIRED':
      return BADGE_THEME.ERROR
    case 'MARKED_FOR_CANCELLATION':
      return BADGE_THEME.INFO
    default:
      return BADGE_THEME.PRIMARY
  }
})

defineOptions({
  name: 'SubscriptionsCheckVisitsInfo',
})
</script>

<i18n>
de:
  active: "aktiv"
  visitsRemaining: "von {total} Besuchen übrig"
  validDays: "{days} verbleibende Tage"
  validCinemas: "Gültige Kinos"
  status:
    ACTIVE: "aktiv"
    EXPIRED: "abgelaufen"
    CANCELLED: "abgebrochen"
    MARKED_FOR_CANCELLATION: "Kündigung eingereicht"
  btn:
    closeThisWindow: "Fenster schließen"
es:
  active: "activo"
  visitsRemaining: "{total} visitas restantes"
  validDays: "{days} días restantes"
  validCinemas: "Cines válidos"
  status:
    ACTIVE: "activo"
    EXPIRED: "expirado"
    CANCELLED: "cancelado"
    MARKED_FOR_CANCELLATION: "Cancelación solicitada"
  btn:
    closeThisWindow: "Cerrar esta ventana"
en:
  active: "active"
  visitsRemaining: "{total} visits remaining"
  validDays: "{days} days remaining"
  validCinemas: "Valid cinemas"
  status:
    ACTIVE: "active"
    EXPIRED: "expired"
    CANCELLED: "cancelled"
    MARKED_FOR_CANCELLATION: "Cancellation requested"
  btn:
    closeThisWindow: "Close this window"
</i18n>
